********* Template CSS *********:root {
  --primary: #044dffb8;
  --light: #F3F6F8;
  --dark: #0E2E50;
}

.container-xxl {
  max-width: 100% !important;
}

.container {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}

body {
  font-family: 'Poppins', sans-serif;
}

.contact_btn span {
  background: #044dffb8;
  border-radius: 20px;
  color: #fff;
  padding: 7px 22px;
}


.btn-check:focus+.btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem #044dff26;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}


::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #e4e3e88f;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #6c7b95;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #888;
}


/* horizontal scrollbar */

::-webkit-scrollbar-track:horizontal {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb:horizontal {
  background: #6c7b95;
}

::-webkit-scrollbar-thumb:hover:horizontal {
  background: #888;
}

.my-6 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.py-6 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}


/*** Navbar ***/
.bg-black {
  background: #2e2c2a;
}

.navbar {
  position: absolute !important;
  width: 100%;
  top: 6px;
  left: 0;
  z-index: 999;
}

.navbar-nav {
  flex-direction: unset;
}

.navbar .navbar-nav .nav-link {
  position: relative;
  margin-left: 38px;
  padding: 55px 0 0;
  color: #ffffffd1;
  font-weight: 400;
  outline: none;
  transition: .5s;
  text-transform: uppercase;
  font-size: 18px;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: #ffffffd1;
}

.navbar .navbar-brand h1 {
  color: #FFFFFF;
}

.navbar .navbar-brand img {
  transition: .5s;
  padding-top: 3px;
  height: 120px;
  margin-top: 9px;
  width: 90px;
  /* width: 100%;
  max-width: 100%; */
}

.navbar-light .navbar-toggler {
  color: #ffffffb8;
  border-color: #b79b47;
}

.bg-grey {
  background: #2E2C2A;

}

.sub-title {
  color: #BA9E48;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.4px;
  text-shadow: 1px 1px 0px #080808;
  margin-bottom: 12px;
  text-transform: uppercase;
}

h2 {
  color: #fff;
  font-size: 30px;
  text-shadow: -1px -1px 5px #000000;
  font-weight: 700;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  line-height: 38px;
}

.common-text {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: 24px;
}

.look_more_btn {
  background: #C09D75;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  border-radius: 40px;
  padding: 10px 20px;
  letter-spacing: 0.4px;
  box-shadow: 0px 5px 5px 0px #00000040;
}

.look_more_btn:hover {
  color: #fff;
}

.idyllic_sec {
  background-image: url('/public/img/idyllic_bg.png');
  background-position: center;
  background-size: 100% 100%;
}

.building_sec {
  background-image: url('/public/img/building_bg.png');
  background-position: center;
  background-size: 100% 100%;
}

.building_process {
  position: relative;
  text-align: center;
  color: #fff;
  font-size: 46px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 52px;
}

.building_process .centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.legends_sec {
  background-image: url('/public/img/legends_bg.png');
  background-position: center;
  background-size: 100% 100%;
}

.map_sec {
  background-image: url('/public/img/map_bg.png');
  background-position: center;
}

.map {
  max-width: 800px;
  width: 100%;
  margin: auto;
}

.map iframe {
  width: 100%;
}

p span {
  color: #C09D75;
}

.gallary_sec {
  background-image: url('/public/img/gallary_bg.png');
  background-position: center;
  background-size: 100% 100%;
}

.gallery_blog {
  position: relative;
}

.gallery_blog .image {
  display: block;
  width: 100%;
  height: auto;
}

.g-header {
  display: flex;
  position: relative;
}

.download {
  position: absolute;
  left: 85%;
  bottom: 0;
  width: 10%;
  padding: 20px;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
  border-radius: 10px;
}

.g-header i:hover {
  color: #6c7b95;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0 0 0 / 69%);
  width: 100%;
  padding: 20px;
  text-align: center;
  opacity: 1;
  color: #fff;
  font-size: 20px;
  text-shadow: -2px 1px 5px #000000;
  font-weight: 700;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  line-height: 25px;
}

h1 {
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  line-height: 43px;
}

h1 span {
  color: #BA9E48;
}

.banner_slider {
  margin-bottom: -6PX;
}

.banner_slider p {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.banner_info {
  position: absolute;
  z-index: 10px;
  bottom: 40% !important;
  left: 125px !important;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
  transform: translateY(50%);
  max-width: 700px;
}

.banner_info h1 {
  font-size: 62px;
  font-weight: bold;
  text-shadow: 2px 3px 7px #000;
}

.banner_info p {
  font-size: 22px;
}


.banner_item {
  position: relative;
}

.banner_img img {
  /* aspect-ratio: 3/2; */
  height: auto;
  object-fit: cover;
}

/*** Footer ***/
.footer .navbar-brand img {
  height: 140px;
}

.footer {
  background: #000;
  color: #ffffffd1;
  font-size: 15px;
  font-weight: 400;
}

.footer h5 {
  color: #b79b47;
}

.footer a {
  color: #ffffffd1;
}

.footer a:hover {
  color: #ffffffd1;
}

.footer_app_icon img {
  width: 151px;
  height: 46px;
  object-fit: cover;
  object-position: 50% 50%;
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 10px;
  padding: 0;
  text-align: left;
  color: #ffffffd1;
  font-weight: 400;
  transition: .3s;
  text-decoration: none;
}


.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 25px 0;
  font-size: 14px;
  border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
  color: var(--light);
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, .1);
}

.footer a {
  text-decoration: none;
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.footer_app_icon a:first-child img {
  border: 0.5px solid #ffffffd1;
  border-radius: 8px;
}

.footer_app_icon a:first-child {
  margin-right: 5px;
}

.pointer {
  cursor: pointer;
}


@media (min-width: 1330px) {
  .container {
    max-width: 1300px;
  }

  .navbar .container {
    max-width: 100%;
    padding-left: 50px;
    padding-right: 50px;
  }

  .banner_info {
    left: 50px;
    bottom: 58%;
  }

}

@media (max-width: 1330px) {
  .download {
    left: 75%;
  }
}


@media (max-width: 768.98px) {
  .banner_img img {
    max-width: 100%;
    object-fit: cover;
    height: 470px;
  }

  .banner_img {
    filter: blur(2px);
  }

  .banner_info {
    top: 120px;
    left: 20px;
  }

  .banner_info h1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    margin-top: 10px;
  }

  .overlay {
    padding: 15px 2px;
    font-size: 18px;
  }

}

@media (max-width: 991.98px) {
  .navbar .navbar-brand img {
    padding: 0;
    margin: 0;
    height: 80px;
  }

  .mb_center {
    text-align: center;
  }

  .banner_info h1 {
    font-size: 32px;
  }

  .navbar .navbar-brand img {
    transition: .5s;
    height: 90px;
    margin-left: -10px;
    width: 70px;
  }

  .navbar .navbar-nav .nav-link {
    padding: 0px;
  }

  .navbar .navbar-nav .nav-link {
    margin-left: 16px;
    padding: 5px 0;
    font-size: 15px;
  }
}


@media (min-width: 992px) {
  .navbar {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
  }


  .navbar .navbar-nav .nav-link::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    bottom: 10px;
    left: 0;
    background: #FFFFFF;
    opacity: 0;
    transition: .5s;
  }
}



@media (max-width:575px) {
  .gallery_blog .image {
    max-width: 350px;
    margin: auto;
  }

  .overlay {
    max-width: 350px;
    left: 0;
    right: 0;
    margin: auto;
  }

  .building_process {
    font-size: 30px;
    line-height: 38px;
  }

  .navbar .navbar-nav .nav-link {
    margin-left: 16px;
    padding: 9px 0;
    font-size: 12px;
  }
  .download {
    font-size: 22px;
    left: 72%;
    line-height: 11px;
  }
}

@media (max-width:330px) {
  .navbar .navbar-nav .nav-link {
    display: none;
  }
}